<template>
    <div class="px-3">
     
  
      <!-- Button Edit Start -->
       <div class="card-body px-0" >
              <div id="ShareLinkStylesAccordion" >
                  <div class="headingText" id="headingThree">
                      <strong class="">
                          <span>Share Icon Styles</span>
                          
                          <a block v-b-toggle.ShareLinkStyles style="float: right; padding-right:20px;  cursor: pointer;" class="arrow-icon-down" data-toggle="collapse" data-target="#buttonSetting" aria-expanded="false" aria-controls="buttonSetting">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                          </a>
                      </strong>
                  </div>
                  <hr>
                  <b-collapse id="ShareLinkStyles" accordion="my-accordion" role="tabpanel">
                       <div class="">
        <div class="mb-2">
  
          <h4 class="editorStyleTitle mt-3">{{isDesktopView ? 'Icon Size:' : 'Mobile Icon Size:'}}</h4>
              <div class="form-group d-flex align-items-center "  v-if="isDesktopView">
               
                <input
              
                  type="range"
                  class="form-control-range formControlRange"
                  min="1"
                  max="5"
                  :key="block.style.IconSize+'desktop'"
                  v-model="block.style.IconSize"
                />
              
                
                 <span  class="ml-3">{{block.style.IconSize}}</span>
                
              </div>
              <div class="form-group d-flex align-items-center "  v-else>
               
                <input
              
                  type="range"
                  class="form-control-range formControlRange"
                  min="1"
                  max="5"
                  :key="block.style.IconMobileSize+'mobile'"
                  v-model="block.style.IconMobileSize"
                />
              
                
                 <span  class="ml-3">{{block.style.IconMobileSize}}</span>
                
              </div>
        </div>
  
        
  
        <div class="button-position d-flex mb-2 align-items-center row">
          <div class="col-12 d-flex justify-content-between align-items-center pr-0">
             <span class="editorStyleTitle "> Icon position: </span>
         <div>
            <span
            type="button"
            class="mx-2 "
            :class="{ 'active-svg': block.style.position === 'left' }"
            @click="block.style.position = 'left'"
          >
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                fill="#323232"
              />
            </svg>
          </span>
          
          <span
            type="button"
            class="mx-2"
            :class="{ 'active-svg': block.style.position === 'center' }"
            @click="block.style.position = 'center'"
          >
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                fill="#6D6B6D"
              />
            </svg>
          </span>
  
          <span
            type="button"
            class="mx-2"
            :class="{ 'active-svg': block.style.position === 'right' }"
            @click="block.style.position = 'right'"
          >
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                fill="#6D6B6D"
              />
            </svg>
          </span>
          </div>
          </div>
         
          
        </div>
  
        <div class="mb-3">
           <div class=" d-flex mb-2 align-items-center row">
          <div class="col-12 d-flex justify-content-between align-items-center pr-0 ">
            <span class="editorStyleTitle">Color: </span>
            <div class="d-flex">
            <input
              type="color"
              v-model="block.style.IconColor"
              style="min-width:30px;width:34.8px; height: 35px"
              class="form-control-color form-control border-0"
            />
           <input type="text" class="ColorTextInput " v-model="block.style.IconColor">
          </div>
          </div>
        </div>
        </div>

        <div class="form-group mt-2">
          <label for="content" class="editorStyleTitle">Text:</label>
         
          <b-form-textarea
            id="content"
            placeholder="Auto height textarea"
            class="input-border-style"
            v-model="block.text"
            max-rows="10"
          ></b-form-textarea>
        </div>

        <div class="row my-2 text-setting">
        <div class="col-8 d-flex align-items-center pr-1 mb-2 ">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-1"
          >
            <path
              d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
              fill="#B3AFB6"
            />
          </svg>

          <select
            class="form-select w-100 form-control-sm pl-2 ml-2 input-border-style"
            aria-label="Default select example"
            v-model="block.style.fontFamily"
          >
          <option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>


          </select>
        </div>
        <div class="col-4 px-0">
              <div class="d-flex align-items-center">
                <span class="mr-1">
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                      fill="#B3AFB6"
                    />
                    <path
                      d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <input
                  type="number"
                  min="0"
                  max="100"
                  style="width: 50px"
                  v-model="block.style.fontSize"
                  class="form-control-sm w-75 px-2 ml-1 input-border-style"
                />
              </div>
            </div>
        
      </div>

      <div class="row my-2 d-flex align-items-center ">
        <div class="col-6 d-flex justify-content-between">
          <span
            class="font-style-button"
            :class="{ 'active-svg': block.style.fontWeight === 'bold' }"
            @click="
              block.style.fontWeight =
                block.style.fontWeight == 'normal' ? 'bold' : 'normal'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            class="mx-1 font-style-button"
            :class="{ 'active-svg': block.style.fontStyle === 'italic' }"
            @click="
              block.style.fontStyle =
                block.style.fontStyle == 'normal' ? 'italic' : 'normal'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            class="mx-1 font-style-button"
            :class="{
              'active-svg': block.style.textDecoration === 'underline',
            }"
            @click="
              block.style.textDecoration =
                block.style.textDecoration == 'underline'
                  ? 'unset'
                  : 'underline'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            :class="{
              'active-svg': block.style.textDecoration === 'line-through',
            }"
            class="font-style-button"
            @click="
              block.style.textDecoration =
                block.style.textDecoration == 'line-through'
                  ? 'unset'
                  : 'line-through'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 15.8333H11.6667V13.3333H8.33333V15.8333ZM4.16667 3.33334V5.83334H8.33333V8.33334H11.6667V5.83334H15.8333V3.33334H4.16667ZM2.5 11.6667H17.5V10H2.5V11.6667Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
        </div>
        <div class="col-6 d-flex align-items-center mt-2">
          <input
            type="color"
            v-model="block.style.color"
            style="min-width: 30px; width: 30px; height: 30px"
            class="form-control-color form-control border-0"
          />
          <!-- <span> {{ block.style.color }}</span> -->
          <input
            type="text"
            class="ColorTextInput input-border-style"
            v-model="block.style.color"
          />
        </div>
        <div class="col-6 mt-2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 5.83332H7.08333L4.16667 2.91666L1.25 5.83332H3.33333V14.1667H1.25L4.16667 17.0833L7.08333 14.1667H5V5.83332ZM8.33333 4.16666V5.83332H18.3333V4.16666H8.33333ZM8.33333 15.8333H18.3333V14.1667H8.33333V15.8333ZM8.33333 10.8333H18.3333V9.16666H8.33333V10.8333Z"
              fill="#B3AFB6"
            />
          </svg>
          <input
            type="number"
            v-model="block.style.lineHeight"
            style="width: 50px"
            class="form-control-sm w-50 px-2 ml-2 input-border-style"
          />
        </div>
      
      </div>
      <div class="form-group d-flex mb-2 align-items-center row">
        <!-- <strong class="pt-2">Background Color: </strong> -->

        <div class="col-6 d-flex align-items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            v-model="block.style.textOpacity"
            class="form-control-sm w-50 px-2 ml-1 input-border-style"
          />
        </div>
        <div class="col-6 d-flex align-items-center px-0">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z"
              fill="#B3AFB6"
            />
          </svg>
          <input
            type="number"
            v-model="block.style.rotate"
            style="width: 50px"
            class="form-control-sm w-50 px-2 ml-1 input-border-style"
          />
        </div>
      </div>
        

      
  
        
       
  
       
        <div class="row m-0 " v-if="isDesktopView">
           <h4 class="editorStyleTitle my-4">Buttons margin:</h4>
          <div class="row align-items-center mb-2">
            <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginTop"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginBottom"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          </div>
          
          <div class="row mb-2 mt-3 align-items-center">
            <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginLeft"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginRight"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          </div>
          
        </div>
        <div class="mt-2" v-else>
          <h4 class="editorStyleTitle mt-4">Mobile Button margin:</h4>
        <div class="row m-0 ">
          <div class="row align-items-center">
            <div class=" col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  min="0"
                  placeholder="Top"
                  v-model="block.style.mobileMarginTop"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          <div class=" col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                   min="0"
                   placeholder="Bottom"
                  v-model="block.style.mobileMarginBottom"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          </div>
          
          <div class="row mt-3">
            <div class="form-group col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.mobileMarginLeft"
                   min="0"
                   placeholder="Left"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-6 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.mobileMarginRight"
                   min="0"
                   placeholder="Right"
                  class="form-control-sm w-75 px-2  input-border-style"
                />
              </div>
            </div>
          </div>
          </div>
          
        </div>
        </div>
      </div>
                    </b-collapse>
                  </div>
              </div>
      <!-- Button Edit End -->
  
          <div class="card-body px-0" >
              <div id="ShareLinkInfoAccordion" >
                  <div class="headingText" id="headingThree">
                      <strong class="">
                          <span>Share Icon Info</span>
                          
                          <a block v-b-toggle.ShareLinkInfo style="float: right; padding-right:20px;  cursor: pointer;" class="arrow-icon-down" data-toggle="collapse" data-target="#ShareLinkInfo" aria-expanded="false" aria-controls="ShareLinkInfo">
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                          </a>
                      </strong>
                  </div>
                  <hr>
                  <b-collapse id="ShareLinkInfo" accordion="my-accordion" role="tabpanel">
                       <div class="">
                          <!-- Whatsapp start -->
                          <div class="mb-2">
                              <div class=" d-flex justify-content-between align-items-center ">
                                  <p class="m-0 p-0">
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.373002 20.0326C0.313845 20.0328 0.255504 20.0188 0.202821 19.9919C0.150137 19.965 0.10463 19.9259 0.0700735 19.8779C0.0355173 19.8299 0.0129092 19.7743 0.00412472 19.7158C-0.00465973 19.6573 0.000632942 19.5976 0.0195634 19.5415L1.48912 15.2184C0.332967 13.2951 -0.148456 11.0409 0.121099 8.81306C0.390654 6.58518 1.39578 4.51092 2.97728 2.91878C4.85492 1.04947 7.39655 0 10.0461 0C12.6955 0 15.2372 1.04947 17.1148 2.91878C18.77 4.59774 19.7803 6.80645 19.9681 9.15659C20.156 11.5067 19.5093 13.8479 18.1419 15.7684C16.7744 17.689 14.7737 19.066 12.4915 19.6574C10.2092 20.2489 7.79151 20.0168 5.66342 19.0021L0.44741 20.0326H0.373002ZM5.71178 18.2282C5.77002 18.2286 5.82736 18.2426 5.8792 18.2692C7.84754 19.251 10.1007 19.5016 12.2366 18.9763C14.3726 18.451 16.2525 17.1839 17.5409 15.4012C18.8294 13.6184 19.4426 11.4359 19.2712 9.24301C19.0998 7.05009 18.1549 4.98937 16.6051 3.42847C15.7438 2.56701 14.7213 1.88365 13.5959 1.41742C12.4705 0.951185 11.2642 0.711217 10.0461 0.711217C8.82788 0.711217 7.62165 0.951185 6.49623 1.41742C5.37081 1.88365 4.34826 2.56701 3.48698 3.42847C2.00516 4.92581 1.07363 6.88053 0.843963 8.97459C0.614293 11.0686 1.09999 13.1788 2.22204 14.9617C2.25182 15.0089 2.27063 15.0621 2.27707 15.1175C2.28351 15.1728 2.27742 15.229 2.25925 15.2817L0.934783 19.1844L5.64481 18.2468L5.71178 18.2282Z" fill="#18191C"/>
<path d="M12.7136 15.1961C12.5996 15.2023 12.4853 15.2023 12.3713 15.1961C10.3958 14.9803 7.90681 13.0271 7.20365 12.3388C6.51537 11.6505 4.55844 9.14669 4.34638 7.17115C4.17524 5.5937 5.22811 4.52222 5.68945 4.13158C5.89573 3.95438 6.16186 3.86257 6.43353 3.87487L7.43803 3.90836C7.51149 3.91108 7.5825 3.9355 7.64209 3.97854C7.70169 4.02158 7.74719 4.08131 7.77287 4.15018C7.99609 4.74173 8.71785 6.70982 8.71785 7.21952C8.71785 7.66597 8.32349 8.01196 7.97377 8.31704C7.89936 8.38028 7.79891 8.46957 7.73939 8.53282C8.1154 9.21999 8.5658 9.86377 9.08245 10.4525C9.67129 10.9691 10.3151 11.4195 11.0022 11.7956C11.0654 11.7324 11.1547 11.6319 11.218 11.5612C11.523 11.2115 11.869 10.8171 12.3341 10.8171C12.8438 10.8171 14.8119 11.5389 15.4034 11.7621C15.4723 11.7878 15.532 11.8333 15.5751 11.8929C15.6181 11.9525 15.6425 12.0235 15.6452 12.097L15.6787 13.1015C15.6864 13.3724 15.5951 13.6369 15.422 13.8456C15.0933 14.2511 14.6814 14.5815 14.2142 14.8145C13.747 15.0474 13.2353 15.1775 12.7136 15.1961ZM7.73195 11.8031C8.34581 12.4169 10.7083 14.2585 12.4531 14.4483C13.6771 14.5785 14.5328 13.7339 14.8454 13.3656C14.9011 13.2962 14.9302 13.2091 14.9272 13.1201L14.9012 12.376C14.0648 12.0406 13.2042 11.7695 12.3266 11.5649C12.2076 11.5649 11.9137 11.9109 11.7872 12.056C11.5342 12.3462 11.3333 12.5732 11.0431 12.5732C10.5706 12.5732 8.92247 11.3529 8.55415 10.9846C8.18584 10.6162 6.9767 8.95695 6.9767 8.49934C6.9767 8.22031 7.20365 8.0194 7.49384 7.75525C7.63522 7.62876 7.97377 7.33857 7.98493 7.2158C7.78034 6.33828 7.5092 5.47762 7.17389 4.64128L6.42981 4.61523C6.3408 4.61225 6.25367 4.64129 6.18426 4.69708C5.81222 5.0096 4.96769 5.86529 5.10162 7.08558C5.29136 8.84161 7.13296 11.178 7.74683 11.8068L7.73195 11.8031Z" fill="#18191C"/>
</svg>
  <span class="text-dark font-weight-medium ml-2"> Whatsapp </span>
                                  </p>
          
          <span class=" custom-control custom-switch mt-2" >
          <input
            type="checkbox"
            class="custom-control-input "
            :id="`showWhatsapp-${Date.now()}`"
            v-model="block.isWhatsappVisible"
          />
          <label class="custom-control-label" :for="`showWhatsapp-${Date.now()}`"></label>
        </span>
        </div>
       
                          </div>
                          <!-- Whatsapp End -->
                          <!-- Telegram start -->
                          <div class="my-2">
                              <div class=" d-flex justify-content-between align-items-center ">
                                  <p class="m-0 p-0">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#18191C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.5266 9.89446C7.4418 8.62435 9.38573 7.78702 10.3584 7.38246C13.1355 6.22737 13.7125 6.02672 14.0887 6.02009C14.1714 6.01863 14.3563 6.03913 14.4762 6.13635C14.5773 6.21844 14.6052 6.32934 14.6185 6.40717C14.6318 6.485 14.6484 6.6623 14.6352 6.80083C14.4847 8.38207 13.8335 12.2193 13.5022 13.9903C13.3621 14.7397 13.0861 14.991 12.8188 15.0156C12.2381 15.069 11.7972 14.6318 11.2347 14.2631C10.3546 13.6861 9.85735 13.327 9.00303 12.764C8.01572 12.1134 8.65575 11.7558 9.21842 11.1714C9.36567 11.0184 11.9243 8.69115 11.9739 8.48003C11.98 8.45362 11.9858 8.3552 11.9273 8.30323C11.8689 8.25126 11.7826 8.26903 11.7203 8.28316C11.632 8.3032 10.226 9.23253 7.50222 11.0711C7.10313 11.3452 6.74164 11.4787 6.41776 11.4717C6.06071 11.464 5.37388 11.2698 4.8633 11.1039C4.23705 10.9003 3.73932 10.7927 3.78266 10.4469C3.80524 10.2669 4.05321 10.0827 4.5266 9.89446Z" fill="white"/>
</svg>
  
  <span class="text-dark font-weight-medium ml-2"> Telegram </span>
                                  </p>
          
          <span class=" custom-control custom-switch mt-2" >
          <input
            type="checkbox"
            class="custom-control-input "
            :id="`showisTelegramVisible-${Date.now()}`"
            v-model="block.isTelegramVisible"
          />
          <label class="custom-control-label" :for="`showisTelegramVisible-${Date.now()}`"></label>
        </span>
        </div>
      
                          </div>
                          <!-- Telegram End -->
                          <!-- Copy Link start -->
                          <div class="my-2">
                              <div class=" d-flex justify-content-between align-items-center ">
                                  <p class="m-0 p-0">
                                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3337 0.833344H3.33366C2.41699 0.833344 1.66699 1.58334 1.66699 2.50001V14.1667H3.33366V2.50001H13.3337V0.833344ZM15.8337 4.16668H6.66699C5.75033 4.16668 5.00033 4.91668 5.00033 5.83334V17.5C5.00033 18.4167 5.75033 19.1667 6.66699 19.1667H15.8337C16.7503 19.1667 17.5003 18.4167 17.5003 17.5V5.83334C17.5003 4.91668 16.7503 4.16668 15.8337 4.16668ZM15.8337 17.5H6.66699V5.83334H15.8337V17.5Z"
                          fill="#4D4950"
                        />
                      </svg>
  
  <span class="text-dark font-weight-medium ml-2"> Copy Link </span>
                                  </p>
          
          <span class=" custom-control custom-switch mt-2" >
          <input
            type="checkbox"
            class="custom-control-input "
            :id="`showisCopyLinkVisible-${Date.now()}`"
            v-model="block.isCopyLinkVisible"
          />
          <label class="custom-control-label" :for="`showisCopyLinkVisible-${Date.now()}`"></label>
        </span>
        </div>
      
                          </div>
                          <!-- Telegram End -->
      </div>
                    </b-collapse>
                  </div>
              </div>
  
  
    </div>
  </template>
  <script>
  import fontFamilyList from "../../../staticPages/fontFamilyList";
  export default {
    props: {
      block: Object,
      index: Number,
       isDesktopView:Boolean,
    },
    data() {
      return {
       
        showBgOpacity:true,
      };
  
    },
    computed:{
      getFontFamilyList() {
return fontFamilyList;
},
    },
    methods:{
      checkHexCode(color){
        if(color){
          let pattern = /^#[0-9A-F]{6}$/i;
             return  color.match(pattern) ? true : false
        }
              
          },
      BgColorOutput(value){
        let isHex = this.checkHexCode(value)
        if(isHex){
          this.showBgOpacity = true
          return value
        } else{
          this.showBgOpacity = false
          return 'Gradient input'
        }
      },
      
    }
  };
  </script>
  <style >
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 2px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
  }
  .reset-option
  {border: 0.3px solid #6D6B6D;
      box-sizing: border-box;
      border-radius: 4px;}
  .form-control-sn {
    border: 1px solid;
    width: 100%;
    border-radius: 3px;
    /* line-height: 1rem; */
    height: 26px;
    font-size: 14px;
  }
  
  .active-svg {
    padding: 3px;
    background: #b3afb6;
    border-radius: 3px;
    
  }
  .active-svg path{
     fill: #323232;
    
  }
  
   input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  
  .button-group {
    color: #535353;
    outline: none;
  }
  .btn-group {
    border: 1px solid #e0dee2;
    border-radius: 2px;
  }
  .btn-group .btn {
    border: none;
  }
  .text-img-btn-selected {
    background: #ffa201 !important;
    color: #ffffff !important;
  }
  
  
  
      .socialInput {
          background: transparent;
          border: none;
          outline: none;
          border-bottom:  1px solid #E0DEE2;
      }
      .socialInput:focus {
          border: none;
          outline: none;
          border-bottom:  1px solid #E0DEE2;
      }
      .socialInput::placeholder {
         font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      
      text-align: start;
      
      color: #D1D1D1;
      }
      .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }
  </style>
  